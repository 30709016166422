import React, { Component } from 'react';
// @ts-ignore
import getOrgAlias from '@devsta/get-alias';
// @ts-ignore
import { Button, Input } from '@devsta/ui-kit';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
import LoginHeader from './LoginHeader';
import { onEnterPressed } from '../../../../utils';
import '../styles/_forgot-password.scss';

type Props = {
  history: any;
  forgotPassword: (...args: Array<any>) => any;
};

type State = {
  email: string;
  emailSent: boolean;
  submitting: boolean;
};

export default class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      emailSent: false,
      submitting: false
    };
  }

  onUpdateField = (fieldName: string) => (
    { target: { value } }: { target: { value: string; }; }
  ) => {
    // @ts-ignore
    this.setState({ [fieldName]: value });
  };

  goToLogin = () => {
    const { history } = this.props;

    history.push('/');
  };

  onSubmit = async () => {
    const { forgotPassword } = this.props;
    const { email } = this.state;

    if (!this.canSubmit(email)) {
      return;
    }

    this.setState({ submitting: true });
    await forgotPassword({ email, alias: getOrgAlias() });

    this.setState({
      submitting: false,
      emailSent: true
    });
  };

  canSubmit = (email: string) => email && email && isEmail(email);

  render() {
    const { email, emailSent, submitting } = this.state;

    return (
      <div className="forgot-password">
        {!emailSent ? (
          <>
            <div className="forgot-password__container">
              <LoginHeader title="Let's get you back up and running" subtitle="Enter your email below and we'll send you a reset link" />
              <Input
                data-cy="login-email-input"
                id="email-input"
                className="forgot-password__field"
                placeholder="johnsmith@email.com  "
                onChange={this.onUpdateField('email')}
                onKeyDown={onEnterPressed({
                  onClick: this.onSubmit
                })}
                prefix="email"
                label="Email Address"
                defaultValue={email}
                validate={(value: string) => !value || isEmail(value)}
                errorMessage="That doesn't look like an email address."
              />
              <Button
                busy={submitting}
                disabled={!this.canSubmit(email)}
                data-cy="reset-password-submit"
                className="forgot-password__submit"
                onClick={this.onSubmit}
              >
                Reset Password
              </Button>
            </div>
          </>
        ) : (
          <div className="forgot-password__container">
            <LoginHeader
              title="Request Submitted"
              subtitle="We’ve sent an email to this email address if it matches a HUB user."
              hideIcon
            />
            <Button
              data-cy="back-to-login-submit"
              className="forgot-password__submit"
              onClick={this.goToLogin}
            >
              Back To Login
            </Button>
          </div>
        )}
      </div>
    );
  }
}
