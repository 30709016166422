const PRO_LINK = { label: 'Pro', url: '/pro', icon: 'video' };
const CAST_LINK = { label: 'Cast', url: '/cast', icon: 'castdef' };

const BOOKINGS_NAV_LINK = {
  label: 'Bookings',
  url: '/bookings',
  icon: 'bookingsdef',
  orgPackageRequired: true,
  onClick: () => window.open('/bookings', '_blank')
};

const topLinks = [PRO_LINK, CAST_LINK];
const coreLinks = [{
  label: 'Scripts',
  url: '/scripts',
  icon: 'script'
}, {
  label: 'Storyboards',
  url: '/storyboards',
  icon: 'storyboard'
}, {
  label: 'Media Library',
  url: '/medialibrary',
  icon: 'medialibrary'
}, {
  label: 'Inspirations',
  url: '/inspiration',
  icon: 'inspiration'
}];

const adminLinks = [{
  label: 'Administration',
  url: '/',
  icon: 'organisation',
  selected: true
}];

export const navRoutes = [
  PRO_LINK,
  { ...CAST_LINK, withSeparator: true },
  ...coreLinks,
  { ...BOOKINGS_NAV_LINK, withSeparator: true },
  ...adminLinks
];

export const mobileNavRoutes = [
  ...topLinks,
  {
    label: 'More Apps',
    icon: 'apps',
    withSeparator: true,
    subMenuRoutes: [
      ...coreLinks,
      { ...BOOKINGS_NAV_LINK, withSeparator: true },
      ...adminLinks
    ]
  },
  BOOKINGS_NAV_LINK,
  { label: 'Live Support', onClick: () => {}, icon: 'livechat' },
  { label: 'Help Center', onClick: () => window.open('https://intercom.help/shootstahub/en', '_blank'), icon: 'faq' }
];
