import React, { Component } from 'react';
// @ts-ignore
import { Switch, Route } from 'react-router-dom';
// @ts-ignore
import getOrgAlias from '@devsta/get-alias';
// @ts-ignore
import { NotFound } from '@devsta/common-react';
import Background from './Background';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import '../styles/_login.scss';
import { getBaseDomain } from '../../../../utils';

const orgAlias = getOrgAlias();
const baseDomain = getBaseDomain();

type Props = {
  getOrganisationNameFromAlias: (...args: Array<any>) => any;
  loginRequest: (...args: Array<any>) => any;
  forgotPassword: (...args: Array<any>) => any;
  history: any;
};

type State = {
  orgFound: boolean;
  checkingOrgName: boolean;
  orgName: string;
};

class Login extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      checkingOrgName: true,
      orgFound: false,
      orgName: ''
    };
  }

  componentDidMount = async () => {
    this.getOrgName();
  };

  getOrgName = async () => {
    const { getOrganisationNameFromAlias } = this.props;
    const result = await getOrganisationNameFromAlias({
      alias: orgAlias
    });

    if (!result || result.error || !result.data || !result.data.getOrganisationNameFromAlias) {
      return this.setState({
        orgFound: false,
        checkingOrgName: false
      });
    }

    const {
      data: {
        getOrganisationNameFromAlias: {
          name
        }
      }
    } = result;

    return this.setState({
      orgFound: true,
      orgName: name,
      checkingOrgName: false
    });
  };

  render() {
    const { orgFound, orgName, checkingOrgName } = this.state;
    const { loginRequest, forgotPassword, history } = this.props;

    if (checkingOrgName) {
      return null;
    }

    if (!orgFound) {
      return <NotFound homeUrl={`${window.location.protocol}//${baseDomain}`} />;
    }

    return (
      <div className="login">
        <div className="login__container" data-cy="login-container">
          <div className="login__left-content">
            <Switch>
              <Route
                path="/login/forgot"
                render={() => (
                  <ForgotPassword history={history} forgotPassword={forgotPassword} />
                )}
              />
              <Route
                render={() => (
                  <LoginForm history={history} loginRequest={loginRequest} orgName={orgName} />
                )}
              />
            </Switch>
          </div>
          <Switch>
            <Route path="/login/forgot">
              <Background type="auth" />
            </Route>
            <Route>
              <Background type="create" />
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default Login;
