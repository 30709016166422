import React, { Component } from 'react';
// @ts-ignore
import { Button, Input, Icon, Text } from '@devsta/ui-kit';
import LoginHeader from '../LoginHeader';
import LoginHelp from '../LoginHelp';
import { onEnterPressed, getBaseDomain } from '../../../../../utils';
import '../../styles/_login-form.scss';

type Props = {
  getOrganisationNameFromAlias: (...args: Array<any>) => any;
  history: Record<string, any>;
};

type State = {
  workspace: string;
  error: string;
  submitting: boolean;
};

export default class WorkspaceForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      workspace: '',
      error: '',
      submitting: false
    };
  }

  /**
   * Updates a field in state
   *
   * @param {string} fieldName
   *
   * @return {Function}
   *
   */
  onUpdateField = (fieldName: string) => (
    { target: { value } }: { target: { value: string; }; }
  ) => {
    // @ts-ignore
    this.setState({ [fieldName]: value, error: '' });
  };

  onSubmit = async () => {
    const { getOrganisationNameFromAlias } = this.props;
    const { workspace } = this.state;

    if (!this.canSubmit(workspace)) {
      return;
    }

    this.setState({
      error: '',
      submitting: true
    });

    const alias = workspace.toLowerCase();
    const result = await getOrganisationNameFromAlias({ alias });

    if (!result || result.error || !result.data || !result.data.getOrganisationNameFromAlias) {
      this.setState({
        error: 'Oops! Looks like the workspace you entered is not valid or does not exist',
        submitting: false
      });

      return;
    }

    window.location.assign(`${window.location.protocol}//${alias}.${window.location.host}`);
  };

  canSubmit = (workspace: string) => workspace;

  render() {
    const { history } = this.props;
    const { workspace, submitting, error } = this.state;
    const urlSuffix = getBaseDomain();

    return (
      <div className="login-form">
        <div className="login-form__body">
          <LoginHeader
            title="Welcome to the HUB"
            subtitle="Sign in to your workspace"
          />
          {error ? (
            <div className="login-form__body__error">
              <Icon name="alert" color="thunderBird" size={15} />
              <p>{error}</p>
            </div>
          ) : null}
          <form className="login-form__body__form">
            <Input
              data-cy="workspace-alias-input"
              data-testid="workspace-alias-input"
              id="workspace-input"
              className="login-form__body__field login-form__body__workspace"
              placeholder="your-workspace"
              autoGrow
              suffixIcon={(
                <Text highlighted>
                  .
                  {urlSuffix}
                </Text>
)}
              value={workspace}
              onChange={this.onUpdateField('workspace')}
              label="Enter your workspace's Shootsta URL"
              autoComplete="workspace"
              onKeyDown={onEnterPressed({ onClick: this.onSubmit })}
            />
          </form>
          <Button
            data-cy="workspace-submit"
            className="login-form__body__submit"
            disabled={!this.canSubmit(workspace)}
            onClick={this.onSubmit}
            busy={submitting}
          >
            Continue
          </Button>
          <LoginHelp
            workspaceLinkText="Not sure which is your workspace?"
            history={history}
          />
          <div className="login-form__footer" />
        </div>
      </div>
    );
  }
}
