import React from 'react';
// @ts-ignore
import { RequestProvider } from '@devsta/common-react';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import LoginComponent from '../components/Login';
import { forgotPasswordApi, getOrgNameFromAliasApi } from '../api';
import { loginApi } from '../../../api';

const Login = ({ history }: { history: any; }) => (
  <RequestProvider requests={{
    login: loginApi(),
    forgotPassword: forgotPasswordApi(),
    getOrganisationNameFromAlias: getOrgNameFromAliasApi()
  }}
  >
    {/* @ts-ignore */}
    {({ login, forgotPassword, getOrganisationNameFromAlias }) => (
      <LoginComponent
        history={history}
        loginRequest={login}
        forgotPassword={forgotPassword}
        getOrganisationNameFromAlias={getOrganisationNameFromAlias}
      />
    )}
  </RequestProvider>
);

export default withRouter(Login);
