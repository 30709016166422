import React from 'react';
// @ts-ignore
import { Icon, Button, Text, Image } from '@devsta/ui-kit';
import { getBaseDomain } from '../../../../utils';
import '../styles/_choose-workspaces.scss';

type Props = {
  orgs?: Record<string, any>[];
};

function goToWorkspace(alias: string) {
  const url = `${window.location.protocol}//${alias}.${getBaseDomain()}`;
  window.open(url);
}

const ChooseWorkspaceList = ({ orgs = [] }: Props) => (
  <div className="page workspaces">
    <div className="workspaces__container">
      <Text heading3>Shootsta Administration</Text>
      <Text disabled>Manage the worksapces that you currently have admin permissions to.</Text>
      <Text heading4 className="workspaces__sub-title">
        Your workspaces
      </Text>
      {orgs.length ? orgs.map(({ name, alias, imageUrl, organisationId }) => (
        <div className="workspaces-item" key={organisationId}>
          <div className="workspaces-item__info">
            <Image
              key="selectable-item-image"
              imageUrl={imageUrl}
              width="60px"
              height="60px"
              defaultIconBackground="parthenon"
              backgroundSize="cover"
              isEditableImage={false}
            />
            <div className="workspaces-item__info-name">
              <Text heading4 noMargin>{name}</Text>
              <Text ellipsisOverflow subtitle noMargin>
                {alias}
                .
                {getBaseDomain()}
              </Text>
            </div>
          </div>
          <Button
            className="launch"
            type="TERTIARY"
            onClick={() => goToWorkspace(alias)}
          >
            Launch
          </Button>
          <Icon
            className="return"
            color="monkey"
            name="returnI"
            onClick={() => goToWorkspace(alias)}
            size={25}
          />
        </div>
      )) : 'No allowed workspaces'}
    </div>
  </div>
);

export default ChooseWorkspaceList;
