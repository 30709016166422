import React from 'react';
// @ts-ignore
import { withPublicLink, AuthorisationConsumer } from '@devsta/common-react';
import CoreLayoutComponent from '../components/CoreLayout';

// @ts-ignore
function CoreLayout({ children, ...props }) {
  return (
    <AuthorisationConsumer>
      {/* @ts-ignore */}
      {({ selectedOrganisation: { organisationId, imageUrl, name, package: organisationPackage } }) => (
        // @ts-ignore
        <CoreLayoutComponent
          logoUrl={imageUrl}
          organisationName={name}
          organisationPackage={organisationPackage}
          organisationId={organisationId}
          {...props}
        >
          {children}
        </CoreLayoutComponent>
      )}
    </AuthorisationConsumer>
  );
}

export default withPublicLink(CoreLayout);
