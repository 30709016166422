import { gql } from 'apollo-boost';

const GENERATE_VERIFICATION_CODE_QUERY = gql`
    mutation generateVerificationCode($email: String!) {
        generateVerificationCode(email: $email)
    }
`;

export default (() => ({
  mutation: GENERATE_VERIFICATION_CODE_QUERY,
  module: 'users',
  buildVariables: ({ email }: { email: string; }) => ({
    email
  })
}));
