import React, { ReactElement, Suspense, lazy } from 'react';
// @ts-ignore
import { Route, Switch, Redirect } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';
import {
  AuthorisationProvider,
  Error,
  NotFound as PureNotFound,
  NotificationsProvider
  // @ts-ignore
} from '@devsta/common-react';
// @ts-ignore
import { Loader } from '@devsta/ui-kit';

// @ts-ignore
import getAlias from '@devsta/get-alias';

// Sub Apps
// @ts-ignore
import Finance from 'adminclient-finance';

// Containers & Layouts
import { AuthProtected, ErrorBoundary } from '../common/containers';
import { CoreLayout } from '../layouts';

import { mapRoutes } from '../utils';
import Workspace from '../common/components/Login/containers/Workspace';
import Verification from '../common/components/Login/containers/Verification';
import Login from '../common/components/Login';
import ChooseWorkspace from '../common/components/ChooseWorkspace';

const NotFound = () => <PureNotFound />;
// @ts-ignore
const UserSettings = lazy(() => import('hub3client-user-settings'));

const financeRoutes = mapRoutes(Finance);

/**
 * Creates the application route components
 *
 * @return {Element}
 */
export default function createRoutes(): ReactElement {
  // @ts-ignore
  window.Intercom('update');

  const alias = getAlias();

  if (!alias) {
    return (
      <ErrorBoundary>
        <NotificationsProvider>
          <ApolloConsumer>
            {(client) => (
              <Switch>
                <Route path="/error" render={() => <Error />} />
                <Route path="/get-workspaces">
                  <Verification client={client} />
                </Route>
                <AuthProtected
                  publicFallback={<Workspace />}
                  makeQuery={client.query}
                >
                  <Route>
                    <ChooseWorkspace />
                  </Route>
                </AuthProtected>
              </Switch>
            )}
          </ApolloConsumer>
        </NotificationsProvider>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <NotificationsProvider>
        <ApolloConsumer>
          {(client) => (
            <Suspense fallback={Loader}>
              <Switch>
                <Route path="/error" render={() => <Error />} />
                <Route path="/get-workspaces"><Verification client={client} /></Route>
                <Route path="/user-settings/(reset-password|invite)" component={UserSettings} />
                <AuthProtected
                  publicFallback={<Login />}
                  makeQuery={client.query}
                >
                  <AuthorisationProvider>
                    <CoreLayout>
                      <Switch>
                        <Route path="/get-workspaces"><Verification client={client} /></Route>
                        <Redirect exact from="/" to="/finance/invoice/pay" />
                        <Route path="/user-settings" component={UserSettings} />
                        {financeRoutes}
                        <Route render={NotFound} />
                      </Switch>
                    </CoreLayout>
                  </AuthorisationProvider>
                </AuthProtected>
              </Switch>
            </Suspense>
          )}
        </ApolloConsumer>
      </NotificationsProvider>
    </ErrorBoundary>
  );
}
