import React, { Component } from 'react';
// @ts-ignore
import { AppSwitcherHeader } from '@devsta/common-react';
import ChooseWorkspaceList from './ChooseWorkspaceList';
import { navRoutes, mobileNavRoutes } from '../../../constants';

type ChooseWorkspaceProps = {
  getUserOrganisations: (...args: Array<any>) => any;
  email: string;
  history: Object;
};

type ChooseWorkspaceState = {
  organisations: Record<string, any>[];
};

export default class ChooseWorkspace extends Component<ChooseWorkspaceProps, ChooseWorkspaceState> {
  constructor(props: ChooseWorkspaceProps) {
    super(props);

    this.state = {
      organisations: []
    };
  }

  componentDidMount() {
    this.fetchOrganisations();
  }

  fetchOrganisations = async () => {
    const { getUserOrganisations, email } = this.props;
    const { data, error } = await getUserOrganisations({ email });

    if (error || !data || !data.getUserOrganisations) {
      return;
    }

    this.setState({ organisations: data.getUserOrganisations });
  };

  render() {
    const { history } = this.props;
    const { organisations } = this.state;

    return (
      <>
        <AppSwitcherHeader
          history={history}
          navRoutes={navRoutes}
          mobileNavRoutes={mobileNavRoutes}
          isAdministration
        />
        <ChooseWorkspaceList orgs={organisations} />
      </>
    );
  }
}
