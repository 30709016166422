import React, { Component } from 'react';
// @ts-ignore
import isEmail from 'validator/lib/isEmail';
import EmailForm from './EmailForm';
import CodeForm from './CodeForm';
import { WorkspaceWrapper } from '../Workspace';
import ChooseWorkspace from '../ChooseWorkspace';

type Props = {
  getVerificationCode: (...args: Array<any>) => any;
  getOrgsForUserCode: (...args: Array<any>) => any;
};

type State = {
  step: number;
  seconds: number;
  isCounterOn: boolean;
  isResend: boolean;
  email?: string;
  code?: string;
  orgs?: Record<string, any>[];
  error?: string;
  submitting?: boolean;
};

const TIMER_IN_SECONDS = 60;

export default class VerificationWizard extends Component<Props, State> {
  timer: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      step: 1,
      error: '',
      seconds: TIMER_IN_SECONDS,
      isCounterOn: false,
      isResend: false
    };
  }

  setEmail = ({ target: { value } }: { target: { value: string; }; }) => {
    this.setState({
      email: value,
      error: ''
    });
  };

  useAnotherAddress = () => {
    this.setState({
      step: 1,
      email: ''
    });
  };

  onVerificationCodeEntered = async (code: string) => {
    const { getOrgsForUserCode } = this.props;
    const { email } = this.state;

    this.setState({
      submitting: true,
      error: ''
    });

    try {
      const { data } = await getOrgsForUserCode({
        email,
        code: code.toUpperCase()
      });

      if (data && data.getOrgsForUserCode) {
        const organisations = data.getOrgsForUserCode;

        if (!organisations || !organisations.length) {
          this.setState({
            submitting: false,
            error: 'Oops! That code was not valid. Try again.'
          });

          return false;
        }

        clearInterval(this.timer);

        this.setState({
          orgs: organisations,
          submitting: false,
          step: 3,
          seconds: TIMER_IN_SECONDS,
          isResend: false,
          isCounterOn: false
        });
      }
    } catch (e) {
      this.setState({
        submitting: false,
        error: ''
      });
    }

    return true;
  };

  resetError = () => {
    this.setState({
      error: ''
    });
  };

  timerTick = () => {
    const { seconds } = this.state;

    if (seconds > 0) {
      this.setState({
        seconds: seconds - 1
      });
    } else {
      clearInterval(this.timer);

      this.setState({
        seconds: TIMER_IN_SECONDS,
        isCounterOn: false
      });
    }
  };

  startCountdown = () => {
    this.setState({
      isCounterOn: true
    });
    this.timer = setInterval(this.timerTick, 1000);
  };

  canSubmitEmail = (email?: string) => email && isEmail(email);

  onEmailFormSubmit = async () => {
    const { getVerificationCode } = this.props;
    const { email, isResend } = this.state;

    if (!this.canSubmitEmail(email)) {
      return;
    }

    this.setState({
      submitting: true,
      error: ''
    });

    try {
      const { data } = await getVerificationCode({ email });

      if (!data || !data.generateVerificationCode) {
        this.setState({
          submitting: false,
          error: 'Oops! Looks like a workspace for the email entered does not exist. Try again.'
        });

        return;
      }

      if (isResend) {
        this.startCountdown();
      }

      this.setState({
        submitting: false,
        isResend: true,
        step: 2
      });
    } catch (e) {
      this.setState({
        submitting: false,
        error: ''
      });
    }
  };

  render() {
    const {
      step,
      error,
      email,
      submitting,
      orgs,
      isCounterOn,
      seconds
    } = this.state;

    return (
      // @ts-ignore
      <WorkspaceWrapper backgroundType={step === 3 ? 'create' : 'lost'}>
        {step === 1
          ? (
            <EmailForm
              onEmailFormSubmit={this.onEmailFormSubmit}
              setEmail={this.setEmail}
              canSubmitEmail={this.canSubmitEmail}
              submitting={submitting}
              email={email}
              error={error}
            />
          )
          : null}
        {step === 2
          ? (
            <CodeForm
              email={email}
              onComplete={this.onVerificationCodeEntered}
              resetError={this.resetError}
              resendCode={this.onEmailFormSubmit}
              error={error}
              isCounterOn={isCounterOn}
              seconds={seconds}
            />
          ) : null}
        {step === 3
          ? (
            <ChooseWorkspace
              orgs={orgs}
              email={email}
              useAnotherAddress={this.useAnotherAddress}
            />
          ) : null}
      </WorkspaceWrapper>
    );
  }
}
