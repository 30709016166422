import React, { memo } from 'react';
// @ts-ignore
import { Text } from '@devsta/ui-kit';
import { ReactComponent as Logo } from '../../../../assets/images/shootsta-logo-colour.svg';
import '../styles/_login-header.scss';

type LoginHeaderProps = {
  title: string;
  subtitle: string;
  hideIcon?: boolean;
};

function LoginHeader({
  title,
  subtitle,
  hideIcon
}: LoginHeaderProps) {
  return (
    <div className="login-header">
      {!hideIcon && <Logo className="login-header__icon" />}
      <Text heading2 noMargin>{title}</Text>
      <Text heading4 noMargin className="login-header__subtitle">{subtitle}</Text>
    </div>
  );
}

export default memo<LoginHeaderProps>(LoginHeader);
