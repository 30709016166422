import React from 'react';
// @ts-ignore
import { RequestProvider } from '@devsta/common-react';
import AuthProtectedComponent from '../components/AuthProtected';
import { loginApi } from '../../../api';
import { refreshTokenApi } from '../api';
import { forgotPasswordApi } from '../../../components/Login/api';

function AuthProtected(props: Record<string, any>) {
  return (
    <RequestProvider requests={{
      login: loginApi(),
      refreshToken: refreshTokenApi(),
      forgotPassword: forgotPasswordApi()
    }}
    >
      {/* @ts-ignore */}
      {({ login, refreshToken, forgotPassword }) => (
        <AuthProtectedComponent
          login={login}
          refreshToken={refreshToken}
          forgotPassword={forgotPassword}
          {...props}
        />
      )}
    </RequestProvider>
  );
}

export default AuthProtected;
