import React from 'react';
// @ts-ignore
import { credentials } from '@devsta/client-auth';
// @ts-ignore
import { RequestProvider } from '@devsta/common-react';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import ChooseWorkspaceComponent from '../components/ChooseWorkspace';
import { getUserOrganisationsApi } from '../api';

const ChooseWorkspace = ({ history }: { history: any; }) => (
  <RequestProvider requests={{
    getUserOrganisations: getUserOrganisationsApi()
  }}
  >
    {/*@ts-ignore*/}
    {({ getUserOrganisations }) => {
      const { user } = credentials.get();

      return (
        <ChooseWorkspaceComponent
          history={history}
          email={user.email}
          getUserOrganisations={getUserOrganisations}
        />
      );
    }}
  </RequestProvider>
);

export default withRouter(ChooseWorkspace);
