export const topNavLinks = [
  {
    label: 'User Management',
    url: '/users',
    icon: 'video',
  },
  {
    label: 'Team Management',
    url: '/teams',
    icon: 'script',
  },
  {
    label: 'Subscriptions',
    url: '/subscriptions',
    icon: 'storyboard',
  },
  {
    label: 'Share settings',
    url: '/sharesettings',
    icon: 'medialibrary',
  },
  {
    label: 'Application Settings',
    url: '/appsettings',
    icon: 'inspiration',
  },
].filter(Boolean);
