import React from 'react';
// @ts-ignore
import { Icon, Button, Text, Link, Image } from '@devsta/ui-kit';
import LoginHeader from './LoginHeader';
import { getBaseDomain } from '../../../../utils';
import '../styles/_login.scss';
import '../styles/_choose-workspaces.scss';

type Props = {
  orgs?: any[],
  email?: string,
  useAnotherAddress?: any
};

function goToWorkspace(alias: string) {
  const url = `${window.location.protocol}//${alias}.${getBaseDomain()}`;

  window.open(url);
}

const ChooseWorkspaceList = ({ orgs = [], email, useAnotherAddress }: Props) => (
  <div className="login-form">
    <div className="login-form__body">
      {
        email ? (
          <div className="login-form__body__success">
            <Icon name="success" color="monkey" size={20} />
            <div className="success-text">
              <Text>
                Confirmed as
                {email}
              </Text>
              <Link onClick={useAnotherAddress}>Use another address</Link>
            </div>
          </div>
        ) : null
      }
      <LoginHeader
        title="Your workspaces"
        subtitle="You're a member of the following workspaces"
      />
      <div className="workspaces-items">
        {
          orgs.length ? orgs.map(({ name, alias, imageUrl, organisationId }) => (
            <div className="workspaces-item" key={organisationId}>
              <div className="workspaces-item__info">
                <Image
                  key="selectable-item-image"
                  imageUrl={imageUrl}
                  width="60px"
                  height="60px"
                  defaultIconBackground="parthenon"
                  backgroundSize="cover"
                  isEditableImage={false}
                />
                <div className="workspaces-item__info-name">
                  <Text heading4 noMargin>{name}</Text>
                  <Text ellipsisOverflow subtitle noMargin>
                    {alias}
                    .
                    {getBaseDomain()}
                  </Text>
                </div>
              </div>
              <Button className="launch" type="TERTIARY" onClick={() => goToWorkspace(alias)}>Launch</Button>
              <Icon className="return" color="monkey" name="returnI" onClick={() => goToWorkspace(alias)} size={25} />
            </div>
          )) : 'No allowed workspaces'
        }
      </div>
      <div className="workspaces-items-help-info">
        <Text>Looking for a different workspace? You can </Text>
        <Link onClick={useAnotherAddress}>try another email address</Link>
        <br />
        <Text> or ask your Workspace Administrator for an invitation</Text>
      </div>
    </div>
  </div>
);

export default ChooseWorkspaceList;
