import { gql } from 'apollo-boost';

const LOGIN_QUERY = gql`
  query ($email: String!, $password: String!, $alias: String!) {
    login(email: $email, password: $password, alias: $alias) {
      auth
      token
      user {
        firstName
        lastName
        userId
        email
        imageUrl
        email
        preferences
        isAvailable
        initialSetup
      }
    }
  }
`;

export default (() => ({
  query: LOGIN_QUERY,
  module: 'users',
  ignoreCache: true,
  buildVariables: ({
    email,
    password,
    alias
  }: {
    email: string;
    password: string;
    alias: string;
  }) => ({
    email,
    password,
    alias
  })
}));
