export default function onEnterPressed({ onClick }: { onClick?: (...args: Array<any>) => any; }) {
  if (!onClick) {
    return null;
  }

  return (event: React.KeyboardEvent<any>) => {
    // Keycode is deprecated in newer browsers but some old ones still use it
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };
}
