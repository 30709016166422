import developmentConfig from './development';
import prodConfig from './production';
import defaultConfig from './default';

export default {
  ...defaultConfig,
  ...(process.env.NODE_ENV !== 'production' ? developmentConfig : prodConfig),
  ...(process.env.USE_DEV_API ? { apiEndpoint: 'https://api-defaultd.develop.shootsta.com/' } : {}),
  HIDE_FEATURES: {}
};
