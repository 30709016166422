import React, { Component } from 'react';
import jsonwebtoken from 'jsonwebtoken';
import { configureScope } from '@sentry/browser';
import queryString from 'query-string';
// @ts-ignore
import { credentials, authLocalStore } from '@devsta/client-auth';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import When from '../../../components/When';
import Reauthenticate from './Reauthenticate';
import config from '../../../../config';
import { redirectToSingleSignOn, getSSOLocalStore } from '../../../../utils';

const MS_PER_SECOND = 1000;

/**
 * PRE_EXPIRE_SECONDS refreshes tokens this many seconds before they actually
 * expire, giving the server a bit of buffer before we potentially send an
 * expired JSONWebToken that could fail auth checks.
 * @type {number}
 */
const PRE_EXPIRE_SECONDS = 30;
type Props = {
  publicFallback?: Node;
  children?: Node;
  refreshToken: (...args: Array<any>) => any;
  login: (...args: Array<any>) => any;
  location: Location;
  history: any;
};
type State = {
  hasToken: boolean;
  isReauthRequired: boolean;
};

function setUserContext(user: any) {
  if (config.sentryDSN) {
    configureScope((scope) => {
      scope.setUser(user);
    });
  }
}

class AuthProtected extends Component<Props, State> {
  static defaultProps = {
    publicFallback: null,
    children: null
  };

  constructor(props: Props) {
    super(props);
    const {
      auth,
      user,
      token
    } = credentials.get();

    setUserContext(user);

    this.state = {
      hasToken: Boolean(token),
      isReauthRequired: Boolean(!auth && token)
    };
    credentials.listenToChange(this.handleCredentialsChange);
  }

  async componentDidMount() {
    const { hasToken, isReauthRequired } = this.state;
    const { refreshToken, location } = this.props;

    const {
      auth,
      user,
      original = null,
      origURL = null,
      ghost
    } = credentials.get();

    const { ssoEnabled } = getSSOLocalStore();

    if (isReauthRequired && ssoEnabled) {
      return void redirectToSingleSignOn(location.pathname);
    }

    if (!hasToken || !auth || !user || user.isAnonymous || ssoEnabled) {
      return;
    }

    const { token } = credentials.get();
    const decodedToken = jsonwebtoken.decode(token);
    const CLOSE_TO_EXPIRY = Math.floor(new Date().getTime() / MS_PER_SECOND + PRE_EXPIRE_SECONDS);

    // @ts-ignore
    if (decodedToken.exp > CLOSE_TO_EXPIRY) {
      // If token within 30s (configurable) of expiry, no need to refresh it
      return;
    }

    try {
      const result = await refreshToken();

      if (result.error || (result.data.refreshToken && result.data.refreshToken.auth === false)) {
        // Clear credentials if refresh of token didn't succeed,
        // user should log in again
        return void credentials.clear();
      }

      const { data: { refreshToken: refreshTokenData } } = result;
      const resultWithOriginal = {
        data: {
          refreshToken: { ...refreshTokenData,
            original,
            origURL,
            ghost
          }
        }
      };
      authLocalStore.save(resultWithOriginal);
    } catch {
      // Silently handle errors and maintain existing credentials.
    }
  }

  handleCredentialsChange = (usercrdls?: {
    auth: boolean | null | undefined;
    token: string | null | undefined;
  }) => {
    if (!usercrdls) {
      return void this.setState({
        hasToken: false,
        isReauthRequired: false
      });
    }

    const { auth, token } = usercrdls;

    this.setState({
      hasToken: Boolean(token),
      isReauthRequired: Boolean(!auth && token)
    }, () => {
      const {
        isReauthRequired
      } = this.state;
      const {
        location
      } = this.props;
      const {
        ssoEnabled
      } = getSSOLocalStore();

      if (!isReauthRequired || !ssoEnabled) {
        return;
      }

      redirectToSingleSignOn(location.pathname);
    });
  };

  render() {
    const {
      publicFallback,
      children,
      login,
      location,
      history
    } = this.props;
    const { redirecting } = queryString.parse(location.search);

    if (redirecting) {
      return null;
    }

    const { hasToken, isReauthRequired } = this.state;

    return (
      // @ts-ignore
      <When condition={hasToken} failWith={publicFallback}>
        {/* @ts-ignore */}
        {isReauthRequired ? <Reauthenticate visible history={history} login={login} /> : children}
      </When>
    );
  }
}

export default withRouter(AuthProtected);
