import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import moment from 'moment';
import memoize from 'memoize-one';
// @ts-ignore
import cx from 'classnames';
// @ts-ignore
import { NavigationMenu } from '@devsta/ui-kit';
// @ts-ignore
import { hasPermission, AppSwitcherHeader } from '@devsta/common-react';
// @ts-ignore
import getOrgAlias from '@devsta/get-alias';
import {
  topNavLinks,
  navRoutes,
  mobileNavRoutes,
  MEDIA_SIZES
} from '../../../common/constants';
import '../styles/_core-layout.scss';

type CoreLayoutProps = {
  children: Node;
  location: Location;
  history: any;
  orgPermissions: Array<string>;
  organisationPackage?: Record<string, any>;
  logoUrl?: string;
  organisationName: string;
  organisationId: string;
  organisationMemberships: Array<Record<string, any>>;
};

type CoreLayoutState = {
  adminMode: boolean;
  appSwitcherVisible: boolean;
};

type IsOpen = {
  isOpen: boolean;
};

const INTERNAL_URL_SEGMENTS = ['internal', 'traffic'];
const SHOOTSTA_ORG_ID = 'f4e7f2c7-7911-4ca9-84f0-4b3ca37249bd';

function getFirstUrlSegment(url: string) {
  return `/${url.split('/')[1]}`;
}

export default class CoreLayout extends Component<CoreLayoutProps, CoreLayoutState> {
  constructor(props: CoreLayoutProps) {
    super(props);

    const { location, history } = props;

    this.state = {
      appSwitcherVisible: false,
      adminMode: this.isInternalUrl(location.pathname)
    };

    history.listen(({ pathname }: { pathname: any }) => {
      if (pathname !== '/') {
        this.setState({
          adminMode: this.isInternalUrl(pathname)
        });
      }
    });
  }

  getRoutes = memoize(({ pathname }, links) => {
    const { organisationPackage, orgPermissions } = this.props;
    const isAdminOrHasPackage = true;
    return links
      .filter(
        // @ts-ignore
        ({ requiredPermission, orgPackageRequired }) => (!requiredPermission || hasPermission(requiredPermission, orgPermissions))
          && (!orgPackageRequired || (orgPackageRequired && organisationPackage))
          && (requiredPermission !== 'VIDEO_TOOL' || isAdminOrHasPackage)
      )
      .map((route: any) => {
        const matchedRoute = route.url === pathname || route.url === getFirstUrlSegment(pathname);
        return matchedRoute ? { ...route, selected: true } : route;
      });
  });

  isActivePackage = () => {
    const { organisationPackage } = this.props;

    // No Pro package
    if (!organisationPackage) {
      return false;
    }

    const { subEnd } = organisationPackage;

    // Unlimited package
    if (subEnd === null) {
      return true;
    }

    // Package exists with an end date
    // returns true if not expired and false if expired
    const now = moment();
    const endDate = moment(subEnd);
    return now.isBefore(endDate);
  };

  isInternalUrl = (pathname: string) => {
    const firstUrlSegment = getFirstUrlSegment(pathname);
    return INTERNAL_URL_SEGMENTS.some((urlSegment) => firstUrlSegment === `/${urlSegment}`);
  };

  toggleAdminMode = () => {
    const { history, organisationMemberships } = this.props;
    const { adminMode: isAdminMode } = this.state;
    const { organisation: shootstaOrg } = organisationMemberships.find(
      (membership) => membership.organisation.organisationId === SHOOTSTA_ORG_ID
    ) || {};
    const shootstaOrgAdminUrl = `${shootstaOrg.alias}.${window.location.host.split('.').slice(1).join('.')}`;
    const trafficLink = shootstaOrg.alias === getOrgAlias() ? '/traffic' : `${shootstaOrgAdminUrl}/traffic`;
    const path = isAdminMode ? '/pro' : trafficLink;
    const useHistory = shootstaOrg.alias === getOrgAlias() || isAdminMode;
    this.setState(
      ({ adminMode }) => ({
        adminMode: !adminMode
      }),
      () => {
        if (useHistory) {
          return history.push(path);
        }

        return window.location.assign(`${window.location.protocol}//${path}`);
      }
    );
  };

  showSwitchOrganisationModal = () => {
    this.setState({
      appSwitcherVisible: true
    });
  };

  hideSwitchOrganisationModal = () => {
    this.setState({
      appSwitcherVisible: false
    });
  };

  createProject = () => {
    const { history } = this.props;
    history.push('/pro/builder/new');
  };

  createCast = () => {
    const { history } = this.props;
    history.push('/launch-cast-app');
  };

  render() {
    const {
      children,
      location,
      history,
      logoUrl,
      organisationName,
      organisationId
    }: CoreLayoutProps = this.props;
    const { adminMode, appSwitcherVisible } = this.state;
    const topRoutes = this.getRoutes(location, topNavLinks);

    return (
      <div className={cx('core-layout', { 'core-layout__no-scroll': appSwitcherVisible })}>
        <AppSwitcherHeader
          className="core-layout__header"
          history={history}
          navRoutes={navRoutes}
          mobileNavRoutes={mobileNavRoutes}
          organisationImageUrl={logoUrl}
          organisationName={organisationName}
          organisationId={organisationId}
          isAdministration
        />
        <MediaQuery minWidth={MEDIA_SIZES.small + 1}>
          <NavigationMenu
            history={history}
            topRoutes={topRoutes}
            primaryColour={adminMode ? 'monkey' : void 0}
            primaryLinksColour={adminMode ? 'white' : void 0}
            primaryLinksHoverColour={adminMode ? 'aqua' : void 0}
            primaryLinksSelectedColour={adminMode ? 'bermudaBlue' : void 0}
            isExpandable
            logoUrl={adminMode ? void 0 : logoUrl}
            title={organisationName}
          />
        </MediaQuery>
        <div className="core-layout__content">
          {children}
        </div>
      </div>
    );
  }
}
