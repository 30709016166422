export default {
  sentryDSN: 'https://410f869116bd48af997bf825989aa035@sentry.io/1293271',
  sentryReleasePrefix: 'defaultd@',
  apiEndpoint: 'https://api-defaultd.s-develop.shootsta.com/',
  serviceEndpoint: 'https://service-defaultd.s-develop.shootsta.com/',
  subscriptionsEndpoint: 'wss://service-defaultd.s-develop.shootsta.com/subscriptions',
  ssoApiEndpoint: 'https://sso-s-develop.shootsta.com/saml',
  shootstaDomainSegments: 'app,develop,staging',
  skipNotifications: 'false'
};
