import { init } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import isElectron from 'is-electron';
import { ApolloProvider } from 'react-apollo';
// @ts-ignore
import globalConfig from 'react-global-configuration';
import 'what-input';
// @ts-ignore
import { credentials, apiClient } from '@devsta/client-auth';
import { AppContainer } from './common/containers';
import Routes from './routes';
import config from './config';
import { version } from '../package.json';
import './styles/_main.scss';

if (config.sentryDSN && !isElectron()) {
  init({ dsn: config.sentryDSN, release: `${config.sentryReleasePrefix}${version}` });
}

globalConfig.set({ HIDE_FEATURES: config.HIDE_FEATURES });

const { user } = credentials.get() || {};

// @ts-ignore
window.Intercom('boot', {
  app_id: 'uvq3bdav',
  custom_launcher_selector: '#nav-item-livesupport',
  ...user
});

const root = document.getElementById('root');

const App = () => (
  <ApolloProvider client={apiClient({ apiEndpoint: config.apiEndpoint })}>
    <AppContainer>
      <Routes />
    </AppContainer>
  </ApolloProvider>
);

if (root != null) {
  ReactDOM.render(<App />, root);
}
