import React from 'react';
import MediaQuery from 'react-responsive';
import ReactCodeInput from 'react-verification-code-input';
// @ts-ignore
import { formatDate } from '@devsta/get-moment';
// @ts-ignore
import { Link, Icon, Text } from '@devsta/ui-kit';
import LoginHeader from '../LoginHeader';

type Props = {
  onComplete: (...args: Array<any>) => any;
  resetError: (...args: Array<any>) => any;
  resendCode: (...args: Array<any>) => any;
  seconds: number;
  isCounterOn: boolean;
  error?: string;
  email?: string;
};

const CodeForm = ({
  onComplete,
  error,
  email = '',
  resetError,
  resendCode,
  seconds,
  isCounterOn
}: Props) => {
  const _CodeInputRef = React.createRef();

  const clearForm = () => {
    if (_CodeInputRef && _CodeInputRef.current) {
      // @ts-ignore
      _CodeInputRef.current.__clearvalues__();
    }

    resetError();
  };

  const resend = () => {
    resendCode();
    clearForm();
  };

  return (
    <div className="login-form">
      <div className="login-form__body">
        <LoginHeader title="Check your email for a code" subtitle={`We've sent a six-digit code to ${email}`} />
        <Text>The code expires shortly, so please enter it soon</Text>
        <MediaQuery minWidth={640}>
          <ReactCodeInput
            className="login-form__code"
            autofocus
            type="text"
            fieldHeight={80}
            fieldWidth={70}
            onComplete={onComplete}
            onChange={resetError}
            // @ts-ignore
            ref={_CodeInputRef}
          />
        </MediaQuery>
        <MediaQuery maxWidth={639}>
          <ReactCodeInput
            className="login-form__code"
            autofocus
            type="text"
            fieldHeight={60}
            fieldWidth={40}
            onComplete={onComplete}
            onChange={resetError}
            // @ts-ignore
            ref={_CodeInputRef}
          />
        </MediaQuery>
        {error ? (
          <div className="login-form__body__error">
            <Icon name="alert" color="thunderBird" size={15} />
            <p>{error}</p>
          </div>
        ) : null}
        <div className="login-form__body__account">
          <div className="login-form__body__help-link">
            {isCounterOn ? (
              <>
                {'Can\'t find your code? Try to resend it in '}
                <span className="login-form__body__counter">
                  {formatDate(seconds * 1000, 'mm:ss')}
                </span>
              </>
            ) : (
              <>
                {'Can\'t find your code? Check your spam folder or '}
                <Link onClick={resend}>
                  resend code.
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="login-form__footer" />
      </div>
    </div>
  );
};

export default CodeForm;
