import React from 'react';
// @ts-ignore
import { RequestProvider } from '@devsta/common-react';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import WorkspaceComponent from '../components/Workspace';
import { getOrgNameFromAliasApi } from '../api';

const Workspace = ({ history }: { history: any; }) => (
  <RequestProvider requests={{
    getOrganisationNameFromAlias: getOrgNameFromAliasApi()
  }}
  >
    {/* @ts-ignore */}
    {({ getOrganisationNameFromAlias }) => (
      <WorkspaceComponent
        history={history}
        getOrganisationNameFromAlias={getOrganisationNameFromAlias}
      />
    )}
  </RequestProvider>
);

export default withRouter(Workspace);
