import React from 'react';
// @ts-ignore
import { Link } from '@devsta/ui-kit';
import '../styles/_login-form.scss';

const LOGON_HELP_ARTICLE = 'https://intercom.help/shootstahub/en/articles/4547921-shootsta-hub-workspaces';

type LoginHelpProps = {
  workspaceLinkText?: string;
  history: Record<string, any>;
};

function goToLogonHelpArticle() {
  window.open(LOGON_HELP_ARTICLE, '_blank');
}

function goToWorkspaceCode(history: Record<string, any>) {
  history.push('/get-workspaces');
}

function LoginHelp({ history, workspaceLinkText }: LoginHelpProps) {
  return (
    <div className="login-form__body__account">
      <div className="login-form__body__help-link">
        {workspaceLinkText || 'Looking for a different workspace?'}
        {' '}
        <Link onClick={() => goToWorkspaceCode(history)}>
          Find your workspace.
        </Link>
      </div>
      <div className="login-form__body__help-link">
        {'Still need help? '}
        <Link onClick={goToLogonHelpArticle}>
          Check this out.
        </Link>
      </div>
    </div>
  );
}

export default LoginHelp;
