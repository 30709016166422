import React, { memo } from 'react';
import WorkspaceForm from './WorkspaceForm';
import WorkspaceWrapper from './WorkspaceWrapper';
import '../../styles/_login.scss';

type Props = {
  getOrganisationNameFromAlias: (...args: Array<any>) => any;
  history: any;
};

const Workspace = ({
  getOrganisationNameFromAlias,
  history
}: Props) => (
  // @ts-ignore
  <WorkspaceWrapper backgroundType="person">
    {/*@ts-ignore*/}
    <WorkspaceForm
      history={history}
      getOrganisationNameFromAlias={getOrganisationNameFromAlias}
    />
  </WorkspaceWrapper>
);

export default memo<Props>(Workspace);
