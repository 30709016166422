import React, { ReactNode } from 'react';
// @ts-ignore
import { Route } from 'react-router-dom';
// @ts-ignore
import { NotFound as PureNotFound, requiresPermission } from '@devsta/common-react';

interface CustomRoute {
  unprotected?: boolean;
  path: string;
}

const NotFound = () => <PureNotFound />;

export default function mapRoutes(routes: CustomRoute[], requiredPermission?: string): ReactNode[] {
  return routes.reduce((newRoutes: ReactNode[], props: CustomRoute) => {
    if (
      (!requiredPermission && !props.unprotected)
        || (requiredPermission && props.unprotected)) {
      return newRoutes;
    }

    const RouteComponent = !requiredPermission
      ? Route
      : requiresPermission({
        ComponentToRender: Route,
        Fallback: NotFound,
        requiredPermission,
        isRoute: true
      });

    return [
      ...newRoutes,
      // eslint-disable-next-line react/jsx-props-no-spreading
      <RouteComponent key={props.path} {...props} />
    ];
  }, []);
}
