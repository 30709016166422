import React from 'react';
// @ts-ignore
import { RequestProvider } from '@devsta/common-react';
// @ts-ignore
import { withRouter } from 'react-router-dom';
import VerificationComponent from '../components/Verification';
import { getVerificationCodeApi, getOrgsForUserCodeApi } from '../api';

const Verification = ({
  history,
  client
}: {
  history: any;
  client: Record<string, any>;
}) => (
  <RequestProvider requests={{
    getVerificationCode: getVerificationCodeApi(),
    getOrgsForUserCode: getOrgsForUserCodeApi()
  }}
  >
    {/* @ts-ignore */}
    {({ getVerificationCode, getOrgsForUserCode }) => (
      <VerificationComponent
        // @ts-ignore
        history={history}
        client={client}
        getVerificationCode={getVerificationCode}
        getOrgsForUserCode={getOrgsForUserCode}
      />
    )}
  </RequestProvider>
);

export default withRouter(Verification);
