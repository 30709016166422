import { gql } from 'apollo-boost';

const REFRESH_TOKEN_QUERY = gql`
  query {
    refreshToken {
      auth
      token
      user {
        firstName
        lastName
        userId
        email
        imageUrl
        email
        preferences
        isAvailable
        initialSetup
      }
    }
  }
`;

export default (() => ({
  query: REFRESH_TOKEN_QUERY,
  module: 'users',
  ignoreCache: true,
  buildVariables: () => ({})
}));
